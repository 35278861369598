import { AppComponent } from 'next/dist/next-server/lib/router/router'
import '../styles/globals.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../store'
import { theme } from '../themes/main'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { init } from '../utils/sentry'

init()

const App: AppComponent = ({ Component, pageProps, err }) => (
  // Err variable is workaround for https://github.com/vercel/next.js/issues/8592
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

export default App
