import { createMuiTheme } from '@material-ui/core';
import { primaryColor } from './colors';
export var theme = createMuiTheme({
  palette: {
    primary: primaryColor,
    text: {
      secondary: '#444444'
    },
    lightBlue: {
      main: '#A9C3CC'
    },
    lightGrey: {
      main: '#CBCBCB'
    },
    darkGrey: {
      main: '#707070'
    },
    darkBlue: {
      main: '#0E3948'
    },
    white: {
      main: '#FFFFFF'
    },
    vagueGreyBorder: {
      main: '#f0f0f0',
      dark: '#cbcbcb'
    }
  },
  typography: {
    h1: {
      fontSize: 30,
      fontFamily: 'Gentium Book Basic',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: 24,
      fontFamily: 'Gentium Book Basic',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: 20,
      fontFamily: 'Gentium Book Basic',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    h5: {
      color: '#444444',
      fontSize: 14
    },
    h6: {
      color: '#444444',
      fontSize: 12
    },
    body1: {
      // Used for paragraphs (brødtekst)
      color: '#444444',
      fontSize: 16
    },
    body2: {
      // Used for buttons
      fontSize: 14
    },
    fontFamily: 'Lato'
  }
});