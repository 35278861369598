import { combineReducers } from 'redux';
import { institutionReducer } from './institution/reducers';
import { authReducer } from './auth/reducers';
import { studyReducer } from './study/reducers';
import { studyProgrammeReducer } from './studyProgramme/reducers';
import { academicTermReducer } from './academicTerm/reducers';
import { courseReducer } from './course/reducers';
import { academicTermCourseReducer } from './academicTermCourse/reducers';
import { curriculumItemReducer } from './curriculumItems/reducers';
import { userReducer } from './user/reducers';
import { userCourseReducer } from './userCourse/reducers';
export var rootReducer = combineReducers({
  auth: authReducer,
  institution: institutionReducer,
  study: studyReducer,
  studyProgramme: studyProgrammeReducer,
  academicTerm: academicTermReducer,
  course: courseReducer,
  academicTermCourse: academicTermCourseReducer,
  curriculumItem: curriculumItemReducer,
  user: userReducer,
  userCourse: userCourseReducer
});