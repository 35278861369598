import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { ADD_USER_COURSE, REMOVE_USER_COURSE, SET_COURSES_FOR_USER, SET_USERS_FOR_COURSE } from './actionTypes';
import isEqual from 'lodash/isEqual';
var initialState = {
  entities: []
};

var userCourseReducer = function userCourseReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ADD_USER_COURSE:
      return {
        entities: [].concat(_toConsumableArray(state.entities.filter(function (userCourse) {
          return !isEqual(userCourse, action.payload);
        })), [action.payload])
      };

    case REMOVE_USER_COURSE:
      return {
        entities: state.entities.filter(function (userCourse) {
          return !isEqual(userCourse, action.payload);
        })
      };

    case SET_USERS_FOR_COURSE:
      var courseId = action.payload.courseId;
      return {
        entities: [].concat(_toConsumableArray(state.entities.filter(function (userCourse) {
          return userCourse.courseId !== courseId;
        })), _toConsumableArray(action.payload.userIds.map(function (userId) {
          return {
            courseId: courseId,
            userId: userId
          };
        })))
      };

    case SET_COURSES_FOR_USER:
      var userId = action.payload.userId;
      return {
        entities: [].concat(_toConsumableArray(state.entities.filter(function (userCourse) {
          return userCourse.userId !== userId;
        })), _toConsumableArray(action.payload.courseIds.map(function (courseId) {
          return {
            courseId: courseId,
            userId: userId
          };
        })))
      };

    default:
      return state;
  }
};

export { userCourseReducer };