import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootReducer';
var persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'] // Only persist auth.

};
var persistedReducer = persistReducer(persistConfig, rootReducer);
var store = createStore(persistedReducer);
var persistor = persistStore(store);
export { store, persistor };