import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { ADD_ACADEMIC_TERM_COURSE, REMOVE_ACADEMIC_TERM_COURSE, SET_COURSES_FOR_ACADEMIC_TERM } from './actionTypes';
import isEqual from 'lodash/isEqual';
var initialState = {
  entities: []
};

var academicTermCourseReducer = function academicTermCourseReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case ADD_ACADEMIC_TERM_COURSE:
      return {
        entities: [].concat(_toConsumableArray(state.entities.filter(function (academicTermCourse) {
          return !isEqual(academicTermCourse, action.payload);
        })), [action.payload])
      };

    case REMOVE_ACADEMIC_TERM_COURSE:
      return {
        entities: state.entities.filter(function (academicTermCourse) {
          return !isEqual(academicTermCourse, action.payload);
        })
      };

    case SET_COURSES_FOR_ACADEMIC_TERM:
      var academicTermId = action.payload.academicTermId;
      return {
        entities: [].concat(_toConsumableArray(state.entities.filter(function (academicTermCourse) {
          return academicTermCourse.academicTermId !== academicTermId;
        })), _toConsumableArray(action.payload.courseIds.map(function (courseId) {
          return {
            academicTermId: academicTermId,
            courseId: courseId
          };
        })))
      };

    default:
      return state;
  }
};

export { academicTermCourseReducer };