import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { DELETE_CURRICULUM_ITEM, SET_CURRICULUM_ITEMS, SET_CURRICULUM_ITEMS_FOR_COURSE } from './actionTypes';
var initialState = {
  entities: {}
};

var curriculumItemReducer = function curriculumItemReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_CURRICULUM_ITEMS_FOR_COURSE:
      return {
        entities: _objectSpread(_objectSpread({}, Object.fromEntries(Object.entries(state.entities).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              curriculumItem = _ref2[1];

          return curriculumItem.courseId !== action.payload.courseId;
        }))), action.payload.curriculumItems)
      };

    case DELETE_CURRICULUM_ITEM:
      return {
        entities: Object.fromEntries(Object.entries(state.entities).filter(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 1),
              id = _ref4[0];

          return id !== action.payload;
        }))
      };

    case SET_CURRICULUM_ITEMS:
      return {
        entities: _objectSpread(_objectSpread({}, state.entities), action.payload)
      };

    default:
      return state;
  }
};

export { curriculumItemReducer };